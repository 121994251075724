import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Image, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkında | Life Kavaklı Spor Salonunu Keşfedin
			</title>
			<meta name={"description"} content={"Gerçek Potansiyelinize Ulaşmanıza Yardımcı Olacak Birinci Sınıf Fitness Hizmetleri!"} />
			<meta property={"og:title"} content={"Hakkında | Life Kavaklı Spor Salonunu Keşfedin"} />
			<meta property={"og:description"} content={"Gerçek Potansiyelinize Ulaşmanıza Yardımcı Olacak Birinci Sınıf Fitness Hizmetleri!"} />
			<meta property={"og:image"} content={"https://glintorix.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://glintorix.com/images/43624643.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://glintorix.com/images/43624643.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://glintorix.com/images/43624643.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://glintorix.com/images/43624643.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://glintorix.com/images/43624643.png"} />
			<meta name={"msapplication-TileImage"} content={"https://glintorix.com/images/43624643.png"} />
			<meta name={"msapplication-TileColor"} content={"https://glintorix.com/images/43624643.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 120px 0" background="--color-darkL2" quarkly-title="Team-15">
			
		<Box
					min-width="100px"
					min-height="100px"
					padding="0px 50px 50px 0px"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					justify-content="center"
					sm-padding="0px 0 0px 0px"
					sm-margin="0px 0px 15px 0px"
				>
					<Text margin="0px 0px 30px 0px" color="--light" font="normal 900 48px/1.2 --fontFamily-sans">
						Hakkında
					</Text>
					<Text margin="0px 0px 40px 0px" color="--lightD2" font="300 18px/140% --fontFamily-sansHelvetica">
					Life Kavaklı Gym'de, fiziksel ve zihinsel sağlığınızın tüm yönlerine hitap eden kapsamlı bir fitness deneyimi sunmaya kendimizi adadık. Geniş hizmet yelpazemiz, fitness seviyeniz veya tercihleriniz ne olursa olsun, başarılı olmak için ihtiyaç duyduğunuz desteği ve kaynakları bulmanızı sağlar.
					</Text>
					<Button
						background="--color-primary"
						color="--dark"
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						padding="15px 44px 15px 44px"
						font="normal 700 14px/1.5 --fontFamily-sansHelvetica"
						text-transform="uppercase"
					>
						Premier Hizmetlerimiz &gt;&gt;
					</Button>
				</Box>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="46px 24px"
				sm-grid-template-columns="1fr"
			>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://glintorix.com/images/6.jpg"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
					Kişisel Eğitim
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
					Özel Fitness Planları: Bireysel hedeflerinizi karşılamak için tasarlanmış özelleştirilmiş egzersizler.
					<br/><br/>
Bire Bir Koçluk: Tekniği mükemmelleştirmek ve sonuçları en üst düzeye çıkarmak için kişisel ilgi.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://glintorix.com/images/7.jpg"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
					Grup Fitness Dersleri
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
					Yoga ve Pilates: Esnekliği, dengeyi ve huzuru artırın.
Spin ve Kardiyo Dersleri: Dayanıklılığı ve kalp sağlığını geliştiren yüksek enerjili seanslar.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://glintorix.com/images/8.jpg"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
					Sağlıklı Yaşam Programları
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
					Beslenme Danışmanlığı: Antrenmanlarınızı ve hayatınızı beslemek için profesyonel tavsiyeler.
Farkındalık ve Stres Yönetimi: Fiziksel zindeliğin yanı sıra zihinsel refahı artırmaya yönelik teknikler.

					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://glintorix.com/images/9.jpg"
						display="block"
						width="100%"
						height="420px"
						object-fit="cover"
						object-position="50% 0"
						margin="0px 0px 30px 0px"
						sm-height="350px"
					/>
					<Text margin="0px 0px 15px 0px" color="--light" font="normal 400 22px/1.2 --fontFamily-sans">
					Gelişmiş Spor Salonu Olanakları
					</Text>
					<Text margin="0px 0px 0px 0px" color="--grey">
					Son Teknoloji Ekipmanlar: Tüm fitness seviyelerine ve tercihlerine hitap eden geniş bir ekipman yelpazesi.
Toparlanma Alanları: Egzersiz sonrası rahatlama ve kasların toparlanması için özel alanlar.

					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" background="#e2e8f0" quarkly-title="About-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="100%" />
			<Box
				display="flex"
				width="40%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="--color-darkL2"
				md-padding="48px 40px 48px 40px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--light"
					font="--headline2"
					lg-text-align="left"
					sm-font="--headline3"
				>
					Bugün Bize Katılın!
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--light"
					font="--base"
					lg-text-align="left"
					md-margin="0px 0px 20px 0px"
				>
					Life Kavaklı Gym'e katılarak zindeliğinizi dönüştürmek için ilk adımı atın. Çeşitli hizmetlerimiz ve destekleyici topluluğumuzla, sadece bir spor salonuna katılmıyorsunuz - sağlıklı yaşam için adanmış bir aileye katılıyorsunuz. Fitness hedeflerinize ulaşmanıza nasıl yardımcı olabileceğimizi keşfetmek için bize ulaşın - Dönüşümünüze Life Kavaklı Gym'de başlayın!
				</Text>
				<Link
					href="/contacts"
					color="--dark"
					text-decoration-line="initial"
					padding="7px 24px 8px 24px"
					background="--color-primary"
					font="--base"
					transition="--opacityOut"
					hover-opacity=".7"
					text-transform="uppercase"
					letter-spacing="1px"
					border-radius="4px"
				>
					Bize Ulaşın
				</Link>
			</Box>
			<Box
				display="flex"
				width="60%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="center"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
				lg-order="-1"
			>
				<Image
					src="https://glintorix.com/images/10.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					max-height="600px"
					width="100%"
					object-position="0% 70%"
					sm-max-height="500px"
				/>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});